import React from 'react'
import { Suspense } from 'react';
import SEO from '../../../components/seo'
import { Router } from "@reach/router"
import { Loading } from '../../../components/common';
const SurveysForm = React.lazy(() => import('../../../Views/Surveys/SurveysForm'));

const IndexPage = () => {
    const isSSR = typeof window === "undefined"
    return (
        <>
            {!isSSR && (
                <Suspense fallback={<Loading />}>
                    <SEO title="Edit Surveys" />
                    <Router>
                        <SurveysForm path="/dashboard/surveys/edit-surveys/:id" />
                    </Router>
                </Suspense>
            )}
        </>
    )
}

export default IndexPage